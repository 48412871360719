<template>
  <div class="">
    <div class="banner">
      <el-carousel height="823px">
        <el-carousel-item v-for="item in imgList" :key="item.index">
          <img :src="photoURL + `${item.image}`" @click="tolink(item.link)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 热门诊断专家 -->
    <div class="all">
      <div class="box">
        <div class="title">
          <h1 style="float: left">热门诊断专家</h1>
          <div style="float: right" class="titleRight" @click="toExpert">
            <h2>查看更多</h2>
            <img class="arrowImg" src="../img/arrow.png" />
          </div>
        </div>
        <div class="recommended">
          <div
            class="recomoutside cursorPointer"
            v-for="(item, index) in expertList"
            :key="index"
          >
            <div class="recomBlock" @click="toexpertDetail(item.id)">
              <div class="img">
                <img :src="photoURL + `${item.head}`" alt="" />
              </div>
              <div>
                <div class="rate">
                  <el-rate
                    v-model="item.score"
                    disabled
                    show-score
                    :colors="colors"
                    text-color="#A4A9B0"
                    score-template="{value}"
                  >
                  </el-rate>
                </div>
                <div class="Recomword1">{{ item.name }}</div>
                <div class="Recomword2">诊断专家·{{ item.city }}</div>
                <div>
                  <span class="Recomword3">¥ {{ item.priceMin }}</span> /次
                </div>
                <div class="Recomword4">
                  {{ item.introduce }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 热门维修师父 -->
        <div class="title">
          <h1 style="float: left">热门维修师傅</h1>
          <div style="float: right" class="titleRight" @click="toExpert">
            <h2>查看更多</h2>
            <img class="arrowImg" src="../img/arrow.png" />
          </div>
        </div>
        <div class="recommended">
          <div
            class="recomoutside cursorPointer"
            v-for="(item, index) in masterList"
            :key="index"
          >
            <div class="recomBlock" @click="toexpertDetail(item.id)">
              <div class="img">
                <img :src="photoURL + `${item.head}`" alt="" />
              </div>
              <div @click="tomasterDetail(item.id)">
                <div class="rate">
                  <el-rate
                    v-model="item.score"
                    disabled
                    :colors="colors"
                    show-score
                    text-color="#A4A9B0"
                    score-template="{value}"
                  >
                  </el-rate>
                </div>
                <div class="Recomword1">{{ item.name }}</div>
                <div class="Recomword2">诊断专家·{{ item.city }}</div>
                <div>
                  <span class="Recomword3">¥ {{ item.priceMin }}</span> /次
                </div>
                <div class="Recomword4">
                  {{ item.introduce }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 大家都在说 -->
      <!-- <div class="title">
      <h2 style="float: left">大家都在说</h2>
      <div style="float: right" class="titleRight">
        <h4>查看更多</h4>
        <img class="arrowImg" src="../img/arrow.png" />
      </div>
    </div>
    <div class="speak">
      <div class="speakLeft">
        <div class="speakBlock">
          <el-row>
            <el-col :span="8">
              <div class="img"></div>
            </el-col>
            <el-col :span="14" :offset="2">
              <div>
                <div class="position">
                  <span style="padding-right: 15px">梁先生</span><span>采购经理</span>
                </div>
                <div class="companyName">湖南华菱湘集团</div>
                <div class="intro">
                  <span
                    >中国载人航天工程办公室主任助理季绍说，神舟十二号载人飞船闻发布会上介绍说，神舟十二号载人飞船飞行乘组由航天员聂海胜、刘伯明和刘伯明和汤洪波三人组成，聂海胜担任指令长，备份航天员为翟志刚、王亚平、叶光富</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="speakLeft">
        <div class="speakBlock">
          <el-row>
            <el-col :span="8">
              <div class="img"></div>
            </el-col>
            <el-col :span="14" :offset="2">
              <div>
                <div class="position">
                  <span style="padding-right: 15px">梁先生</span><span>采购经理</span>
                </div>
                <div class="companyName">湖南华菱湘集团</div>
                <div class="intro">
                  <span
                    >中国载人航天工程办公室主任助理季绍说，神舟十二号载人飞船闻发布会上介绍说，神舟十二号载人飞船飞行乘组由航天员聂海胜、刘伯明和刘伯明和汤洪波三人组成，聂海胜担任指令长，备份航天员为翟志刚、王亚平、叶光富</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div> -->
    </div>
    <img class="banner2" src="../img/banner1.png" />
    <img class="banner2 " src="../img/banner2.png" />
    <img class="banner2 " src="../img/banner5.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value2: null,
      masterList: [],
      expertList: [],
      imgList: [],
      colors: ["#FF9F0A", "#FF9F0A", "#FF9F0A"],
    };
  },
  methods: {
    toExpert() {
      this.$router.push({
        path: "/diagnosisExpert",
      });
    },
    toMatser() {
      this.$router.push({
        path: "/maintainMatser",
      });
    },
    toexpertDetail(id) {
      this.$router.push({
        path: "/diagnosisDetails",
        query: { id, num: "1" },
      });
    },
    tomasterDetail(id) {
      this.$router.push({
        path: "/diagnosisDetails",
        query: { id, num: "2" },
      });
    },
    // 获取banner
    getbannerList() {
      this.$get("/shop-api/mall/banner/list", {
        os: 1,
        type: "CHECK_FIX_BANNER",
      }).then((data) => {
        if (data.code === 1000) {
          this.imgList = data.data;
        }
      });
    },
    // 获取专家列表
    getExpertList() {
      this.$get("/shop-api/mall/expert/list", {
        skillType: 1,
        current: 1,
        size: 16,
      }).then((data) => {
        if (data.code == 1000) {
          this.expertList = data.data.records.slice(0, 4);
          this.total = data.data.total;
        }
      });
    },
    // 获取师傅列表
    getmastertList() {
      this.$get("/shop-api/mall/expert/list", {
        skillType: 2,
        current: 1,
        size: 16,
      }).then((data) => {
        if (data.code == 1000) {
          this.masterList = data.data.records.slice(0, 4);
          this.total = data.data.total;
        }
      });
    },
    tolink(url){
      window.location.href = url;
    }
  },
  mounted() {
    this.getbannerList();
    this.getExpertList();
    this.getmastertList();
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: #f5f5f5;
  margin: 0 auto;
  img {
    height: 823px;
    width: 100%;
	// object-fit:contain;
  }
}
.all {
  background-color: #f5f5f5;
  .box {
    padding: 30px 0;
    margin: 0 auto;
    width: 1440px;
    .title {
      margin-top: 25px;
      height: 33px;
      line-height: 33px;
      color: #333333;
      padding: 0 15px;
      .titleRight {
        cursor: default;
        display: flex;
        align-items: center;
        .arrowImg {
          width: 22px;
        }
      }
    }
    .recommended {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      .recomoutside {
        display: flex;
        justify-content: center;
        align-items: center;
        .recomBlock {
          border-radius: 5px;
          background-color: white;
          width: 340px;
          margin: 10px;
          text-align: center;
          .img {
            width: 143px;
            height: 143px;
            margin: 25px auto;
            border-radius: 50%;
            border: 1px dotted #d1d1d6;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .Recomword1 {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            margin: 16px 0;
          }
          .Recomword2 {
            color: #888888;
            font-size: 14px;
            margin: 10px 0 12px;
          }
          .Recomword3 {
            font-size: 20px;
            font-weight: 600;
            color: #4a99f6;
          }
          .Recomword4 {
            margin: 30px auto 40px;
            width: 300px;
            height: 42px;
            font-size: 14px;
            color: #888888;
            text-align: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .rate {
            margin: 12px 0;
          }
          &:hover {
            box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
 
    .speak {
      width: 1500px;
      margin: 0 auto;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-around;
      .speakLeft {
        width: 720px;
        background-color: white;
        border: 1px solid #d1d1d6;
        .speakBlock {
          width: 550px;
          margin: 20px auto;
          .img {
            width: 200px;
            height: 200px;
            border: 1px dotted #d1d1d6;
          }
          .position {
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            margin: 10px 0px;
          }
          .companyName {
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-bottom: 10px;
          }
          .intro {
            font-size: 14px;
            font-weight: 400;
            color: #a4a9b0;
            line-height: 24px;
          }
        }
      }
      .speakLeft:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
}
   .banner2 {
		width: 100%;
		display:block;
    }
</style>
